export const IS_PRD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_STG = (process.env.NODE_ENV as any) === 'staging';
export const REACT_APP_API_BASE_URL = IS_DEV
  ? `http://localhost:${process.env.REACT_APP_SERVER_APP_PORT}`
  : process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const REACT_APP_COGNITO_USER_POOL_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_ID_FOR_ORGANIZATION;
export const REACT_APP_COGNITO_USER_POOL_CLIENT_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID_FOR_ORGANIZATION;

export const COOKIE_DOMAIN = IS_DEV
  ? 'localhost'
  : process.env.REACT_APP_ORGANIZATION_URL!.match(/(?<=https:\/\/).*/)![0];

export const S3_PATH = 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/';
export const KURASHI_STUDY_API_PATH = 'https://api-manager.kurashi-study.net/cms/';

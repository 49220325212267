import { ChakraProvider, theme } from '@chakra-ui/react';
import { Amplify } from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import ja from 'date-fns/locale/ja';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import {
  COOKIE_DOMAIN,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  REACT_APP_COGNITO_USER_POOL_ID,
} from './configs/env-config';
import './index.scss';
import { history, store } from './store';
registerLocale('ja', ja);

Amplify.configure({
  region: REACT_APP_COGNITO_REGION,
  userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  Auth: {
    cookieStorage: {
      domain: COOKIE_DOMAIN,
      secure: true,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ChakraProvider>
  </Provider>,
  document.getElementById('root'),
);

import { AuthState } from '@aws-amplify/ui-components';
import {
  Box,
  Container,
  Flex,
  Icon,
  Link,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Button,
  Divider,
  Text,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { routerActions } from 'connected-react-router';
import React, { memo, useEffect, useState } from 'react';
import {
  FiArchive,
  FiBell,
  FiCalendar,
  FiFileText,
  FiLayers,
  FiSettings,
  FiUser,
  FiLogOut,
} from 'react-icons/fi';
import { TiArrowSortedDown } from 'react-icons/ti';
import { MdPeopleAlt } from 'react-icons/md';
import { GiTeacher } from 'react-icons/gi';
import { GrUserSettings } from 'react-icons/gr';
import { useAppDispatch, useAppSelector } from '../hooks';
import { signOutAsync } from '../slice/userSlice';
import './shell.scss';

const NavItem = ({ icon, title, active, onClick }: any) => {
  return (
    <Flex mt={30} flexDir="column" w="100%">
      <Menu placement="right">
        <Link
          backgroundColor={active ? '#cbe7ff' : 'inherit'}
          p={3}
          borderRadius={2}
          _hover={{ textDecor: 'none', backgroundColor: '#e8f4ff' }}
          w={'auto'}
          onClick={onClick}
        >
          <MenuButton w="100%">
            <Flex>
              <Icon as={icon} fontSize="xl" color={active ? '#82AAAD' : 'gray.500'} />
              <Text ml={5} display={'flex'}>
                {title}
              </Text>
            </Flex>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
};

const navs = [
  { icon: FiBell, title: 'お知らせ', active: false, pathname: '/notices' },
  { icon: FiUser, title: '学習者', active: false, pathname: '/learners' },
  { icon: GiTeacher, title: '学習支援者', active: false, pathname: '/teachers' },
  { icon: FiCalendar, title: 'レッスン枠', active: false, pathname: '/lessons' },
  { icon: FiLayers, title: 'グループ', active: false, pathname: '/groups' },
  {
    icon: () => <GrUserSettings className="userSettingsIcon" />,
    title: '運営アカウント',
    active: false,
    pathname: '/organizationusers',
  },
  { icon: FiFileText, title: '当月データ', active: false, pathname: '/reports' },
  { icon: FiArchive, title: '月別データ', active: false, pathname: '/archives' },
  { icon: FiSettings, title: '料金設定', active: false, pathname: '/settings' },
];

export interface IShellProps {
  children: React.ReactNode;
}

export var orgKey = React.createContext('');
const getOrgKey = async () => {
  const authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) => null);
  if (authenticatedUser) {
    const orgName = await authenticatedUser.attributes['custom:organizationKey'];
    return orgName;
  }
  return '';
};

export const Shell: React.NamedExoticComponent<IShellProps> = memo(({ children }) => {
  const dispatch = useAppDispatch();
  const historyState = useAppSelector((state) => state.router);
  const userState = useAppSelector((state) => state.user);
  const [organizationKey, setOrganizationKey] = useState<any>('');
  const onClickBrand = () => dispatch(routerActions.push({ pathname: '/top' }));
  const onClickProfile = (close: () => void) => {
    // TODO
    close();
  };

  useEffect(() => {
    if (organizationKey === '') {
      getOrgKey().then((orgName) => {
        orgKey = React.createContext(orgName);
        setOrganizationKey(orgName);
      });
    }
    return;
  });

  const onClickSignout = async () => {
    await dispatch(signOutAsync());
  };

  return (
    <>
      <Box
        display="flex"
        height="60px"
        borderBottom="1px solid #ddd"
        justifyContent="space-between"
        boxShadow={'sm'}
        alignItems="center"
      >
        <Box alignItems="center" display="flex" flexWrap="wrap">
          <Text
            onClick={onClickBrand}
            style={{ fontSize: '18px', fontWeight: 600, cursor: 'pointer' }}
            paddingLeft={30}
          >
            {organizationKey}
            {organizationKey == 'kokoka' ? 'にほんご' : ''}
          </Text>
          <br />
          <sub style={{ color: '#3f83bf', marginLeft: '5px', bottom: 'unset' }}>
            {organizationKey.length > 0 ? ' | ' : ''} 運営管理システム
          </sub>
        </Box>
        <Box hidden={!(userState.authState === AuthState.SignedIn && userState.user)}>
          <Menu>
            <MenuButton
              as={Button}
              fontSize="12px"
              fontWeight={500}
              marginRight={3}
              height={'32px'}
              background={'white'}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="4px"
              rightIcon={<TiArrowSortedDown size={13} />}
              _hover={{ background: 'white', borderColor: 'gray.500' }}
              _focus={{ background: 'white', boxShadow: 'none' }}
              _active={{ background: 'white' }}
            >
              {userState.user?.email}
            </MenuButton>
            <MenuList boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)">
              <MenuItem
                as={Button}
                fontSize="14px"
                mb={'2'}
                fontWeight={400}
                leftIcon={
                  <Icon
                    as={MdPeopleAlt}
                    boxSize="22px"
                    color="#474d66"
                    fontWeight="bold"
                    margin={'5px'}
                  />
                }
                background={'white'}
                color="#474d66"
                justifyContent="flex-start"
                borderRadius={0}
                _hover={{ background: '#f9fafc', color: '#696f8c' }}
                _focus={{ background: 'transparent', boxShadow: 'none' }}
                _active={{ background: 'transparent' }}
              >
                Profile
              </MenuItem>
              <Divider />
              <MenuItem
                as={Button}
                fontSize="14px"
                mt={'2'}
                mb={'1'}
                fontWeight={400}
                leftIcon={
                  <Icon
                    as={FiLogOut}
                    boxSize="22px"
                    color="red.500"
                    fontWeight="bold"
                    margin={'5px'}
                  />
                }
                background={'white'}
                onClick={onClickSignout}
                color="red.500"
                borderRadius={0}
                justifyContent="flex-start"
                _hover={{ background: '#f9fafc', color: 'red.500' }}
                _focus={{ background: 'transparent', boxShadow: 'none' }}
                _active={{ background: 'transparent' }}
              >
                Signout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
      <Flex>
        <Flex
          pos="sticky"
          borderRight="1px solid #ddd"
          boxShadow="sm"
          minW="200px"
          flexDir="column"
          justifyContent="space-between"
          top="0"
          hidden={!(userState.authState === AuthState.SignedIn && userState.user)}
        >
          <Flex
            position="sticky"
            top="0"
            p="15px"
            flexDir="column"
            w="100%"
            alignItems={'flex-start'}
            as="nav"
          >
            {navs.map((nav) => (
              <NavItem
                key={nav.title}
                icon={nav.icon}
                title={nav.title}
                active={nav.pathname === historyState.location.pathname}
                onClick={() => dispatch(routerActions.push(nav.pathname))}
              />
            ))}
          </Flex>
        </Flex>
        <Container className="Shell-children">{children}</Container>
      </Flex>
    </>
  );
});
